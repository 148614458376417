import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import Image from '../../Image';
import { Sizes } from '../../../constants/Image';
import useStores from '../../../hooks/useStores';
import './styles/JobOfferBanner.scss';
import parse from 'html-react-parser';

const JobOfferBanner = () => {
  const { banners } = useStores();
  const [image, setImage] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    loadBannerByCollection();
  }, []);

  const loadBannerByCollection = async () => {
    const imageData = await banners.loadByCollection(
      'component-jobOfferBanner',
    );
    setImage(imageData);
  };

  const openModalFunction = () => {
    setOpen(true);
    document.body.style.overflow = 'hidden';
  };

  const closeModalFunction = () => {
    document.body.style.overflow = 'visible';
    setOpen(false);
  };

  const modal = () => {
    return (
      <Modal
        isOpen={open}
        overlayClassName={'component-jobOfferBanner-modal__overlay'}
        ariaHideApp={false}
        onRequestClose={closeModalFunction}
        className="component-jobOfferBanner-modal"
      >
        <div className="component-jobOfferBanner-modal__container">
          <div className="component-jobOfferBanner-modal__header">
            <h4>Täida oma andmed</h4>
            <div
              className="component-jobOfferBanner-modal__close"
              onClick={closeModalFunction}
            />
          </div>
          <div className="component-jobOfferBanner-modal__content">
            <iframe
              className="component-jobOfferBanner-modal__iframe"
              id="rl-form-1"
              src="https://rademar.recruitlab.ee/public/forms/4Mgj959Nm0FUg0qkjl9hiAwhgGgtSUPDoSkqmU6B"
            />
          </div>
        </div>
      </Modal>
    );
  };

  if (!image) {
    return null;
  }

  return (
    <section className="component-jobOfferBanner">
      <div className="component-jobOfferBanner-container">
        {image
          ? image.map((item, index) => {
              return (
                <>
                  <div key={index} className="component-jobOfferBanner-image">
                    <Image image={item.cover} defaultSize={Sizes.W800} />
                  </div>
                  <div className="component-jobOfferBanner-content">
                    <div className="component-jobOfferBanner-info">
                      {parse(item.content)}
                    </div>
                    {item.button && (
                      <div className="component-jobOfferBanner-button">
                        <p onClick={openModalFunction}>{item.button}</p>
                      </div>
                    )}
                  </div>
                </>
              );
            })
          : null}
      </div>
      {modal()}
    </section>
  );
};

export default JobOfferBanner;
