import React, { useEffect, useState } from 'react';
import useStores from '../../../hooks/useStores';
import './styles/JobOfferImages.scss';
import JobOfferImage from './JobOfferImage';

const JobOfferImages = () => {
  const { banners } = useStores();
  const [collection, setCollection] = useState([]);

  useEffect(() => {
    loadBannersByCollection();
  }, []);

  const loadBannersByCollection = async () => {
    const data = await banners.loadByCollection('component-jobOfferImages');
    setCollection(data);
  };

  if (!collection) {
    return null;
  }

  return (
    <section className="component-jobOfferImages">
      <div className="component-jobOfferImages-list">
        {collection.map((item, index) => {
          return <JobOfferImage data={item} key={index} />;
        })}
      </div>
    </section>
  );
};

export default JobOfferImages;
