import React, { useEffect, useState } from 'react';
import useStores from '../../../hooks/useStores';
import parse from 'html-react-parser';
import './styles/JobOfferBoxes.scss';

const JobOfferBoxes = () => {
  const { banners, jobOffers } = useStores();
  const [collection, setCollection] = useState([]);

  useEffect(() => {
    loadBannersByCollection();
  }, []);

  const loadBannersByCollection = async () => {
    const data = await banners.loadByCollection('component-jobOfferBoxes');
    setCollection(data);
  };

  const scrollToRef = () => {
    if (jobOffers.ref.current) {
      jobOffers.ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  if (!collection) {
    return null;
  }

  return (
    <section className="component-jobOfferBoxes">
      {collection.map((item, index) => {
        let imageURL;

        if (item.cover) {
          imageURL = item.cover.urls['W1000'];
        }

        return (
          <div className="component-jobOfferBoxes__box" key={index}>
            <div
              className="component-jobOfferBoxes__image"
              style={{
                backgroundImage: 'url(' + `${imageURL}` + ')',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
              }}
            ></div>
            <div className="component-jobOfferBoxes__content">
              <div>{parse(item.content)}</div>
              {item.button && item.url ? (
                <div
                  onClick={scrollToRef}
                  className="component-jobOfferBoxes__button"
                >
                  <p>Tutvu tööpakkumistega</p>
                </div>
              ) : null}
            </div>
          </div>
        );
      })}
    </section>
  );
};

export default JobOfferBoxes;
