import React, { useState } from 'react';
import Image, { ImageData } from '../../Image';
import { Sizes } from '../../../constants/Image';
import parse from 'html-react-parser';
import './styles/JobOfferImage.scss';

interface Props {
  data: {
    title: string;
    content: string;
    cover: ImageData;
  };
}

function JobOfferImage({ data }: Props) {
  const [open, setOpen] = useState(false);

  const toggleTooltip = () => {
    setOpen(!open);
  };

  return (
    <div className={`component-jobOfferImage ${open ? 'open' : ''}`}>
      <p className="component-jobOfferImage-title">{data.title}</p>
      {data.content ? (
        <div className="component-jobOfferImage-tooltip">
          {parse(data.content)}
        </div>
      ) : null}
      <div className="component-jobOfferImage-image">
        <Image image={data.cover} defaultSize={Sizes.W540} />
        {data.content ? (
          <div
            className="component-jobOfferImage-tip"
            onMouseEnter={toggleTooltip}
            onMouseLeave={toggleTooltip}
          />
        ) : null}
      </div>
    </div>
  );
}

export default JobOfferImage;
