import React, { useEffect, useState } from 'react';
import Slider, { LazyLoadTypes } from 'react-slick';
import useStores from '../../../hooks/useStores';
import Left from '../../SlickArrow/Left';
import Right from '../../SlickArrow/Right';
import Image from '../../Image';
import { Sizes } from '../../../constants/Image';
import './styles/JobOffersCarousel.scss';

const JobOffersCarousel = () => {
  const { banners } = useStores();
  const [collection, setCollection] = useState([]);

  useEffect(() => {
    loadBannersByCollection();
  }, []);

  const loadBannersByCollection = async () => {
    const data = await banners.loadByCollection('component-jobOffersCarousel');
    setCollection(data);
  };

  if (!collection) {
    return null;
  }

  const sliderConfig = {
    lazyLoad: 'ondemand' as LazyLoadTypes,
    slidesToShow: 4,
    slidesToScroll: 4,
    centerMode: true,
    infinite: true,
    dots: false,
    dotsClass: 'awesome-dots',
    customPaging: () => {
      return <span />;
    },
    prevArrow: <Left />,
    nextArrow: <Right />,
    responsive: [
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1.1,
          centerMode: true,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1.5,
          centerMode: true,
        },
      },
    ],
  };

  return (
    <section className="component-jobOffersCarousel">
      <div className="component-jobOffersCarousel-title">
        <h2>#teamrademar</h2>
      </div>
      <Slider {...sliderConfig}>
        {collection
          ? collection.map((image, index) => {
              return (
                <div key={index} className="component-jobOffersCarousel-image">
                  <Image image={image.cover} defaultSize={Sizes.W540} />
                </div>
              );
            })
          : null}
      </Slider>
    </section>
  );
};

export default JobOffersCarousel;
