import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
// import Select from 'react-select';
import useStores from '../../../hooks/useStores';
import JobOfferItem from './JobOfferItem';
import './styles/JobOffersSection.scss';
import Translate from '../../Translate/Translate';

const JobOffersSection = () => {
  const { jobOffers } = useStores();

  useEffect(() => {
    loadJobOffers();
  }, []);

  const loadJobOffers = async () => {
    if (!jobOffers.loaded) {
      await jobOffers.load();
    }
  };

  // const options = [
  //   {
  //     value: 'esimene',
  //     label: 'esimene',
  //   },
  // ];

  if (!jobOffers.offers.length) {
    return (
      <section className="component-jobOffers" ref={jobOffers.ref}>
        <div className="component-jobOffers-nooffers">
          <h3>
            <Translate component="job-offer" keyword="no-offers" />
          </h3>
        </div>
      </section>
    );
  }

  return (
    <section className="component-jobOffers">
      <div className="component-jobOffers-container">
        <div className="component-jobOffers-header">
          <h2>Vabad töökohad</h2>
          {/* <div className="component-jobOffers-dropdowns">
            <div className="component-jobOffers-dropdown">
              <p>Töö iseloom</p>
              <Select
                options={options}
                className="component-jobOffers-dropdown__class"
                classNamePrefix="component-jobOffers-dropdown__class"
                placeholder={'Vali'}
              />
            </div>
            <div className="component-jobOffers-dropdown">
              <p>Asukoht</p>
              <Select
                options={options}
                className="component-jobOffers-dropdown__class"
                classNamePrefix="component-jobOffers-dropdown__class"
                placeholder={'Vali'}
              />
            </div>
          </div> */}
        </div>
        <div className="component-jobOffers-content">
          <div className="component-jobOffers-list" ref={jobOffers.ref}>
            {jobOffers.offers
              ? jobOffers.offers.map((offer, index) => (
                  <JobOfferItem key={index} data={offer} />
                ))
              : null}
          </div>
        </div>
      </div>
    </section>
  );
};

export default observer(JobOffersSection);
