import React from 'react';
import Translate from '../../../components/Translate/Translate';
import JobOfferBanner from './JobOfferBanner';
import JobOfferBoxes from './JobOfferBoxes';
import JobOfferImages from './JobOfferImages';
import JobOffersCarousel from './JobOffersCarousel';
import JobOffersSection from './JobOffersSection';
import JobOfferVideo from './JobOfferVideo';
import { Language } from '../../../stores/Translations';
import { translations } from '../../../stores';
import './styles/index.scss';

const JobOffersPage = () => {
  const currentLanguage = translations.language;
  return (
    <div className="component-jobOffersPage">
      <JobOfferVideo />
      {currentLanguage === Language.RU ? null : <JobOfferBoxes />}
      <div className="component-jobOffersPage-title">
        <h2>
          <Translate component="jobOffers" keyword="title" />
        </h2>
        <p>
          <Translate component="jobOffers" keyword="sub-title" />
        </p>
      </div>
      <JobOfferImages />
      <JobOffersSection />
      {currentLanguage === Language.RU ? null : <JobOfferBanner />}
      <JobOffersCarousel />
    </div>
  );
};

export default JobOffersPage;
