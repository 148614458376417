import React from 'react';
import './styles/JobOfferItem.scss';

interface Props {
  data: {
    location: string;
    title: string;
    rl_landing_page_url: string;
    deadline: string;
    media: ImageData[];
  };
}

const JobOfferItem = ({ data }: Props) => {
  return (
    <div className="component-jobOffer">
      <div className="component-jobOffer-container">
        <div className="component-jobOffer-image"></div>
        <div className="component-jobOffer-content">
          <div className="component-jobOffer-location">
            <p>{data.location}</p>
          </div>
          <div className="component-jobOffer-title">
            <h4>{data.title}</h4>
          </div>
          <div className="component-jobOffer-date">
            <p>Kandideerimise tähtaeg:</p>
            <span>{data.deadline}</span>
          </div>
          <a
            className="component-jobOffer-button"
            href={`${data.rl_landing_page_url}`}
            target="_blank"
            rel="noreferrer"
          >
            <p>Kandideeri</p>
          </a>
        </div>
      </div>
    </div>
  );
};

export default JobOfferItem;
