import React from 'react';
import './styles/JobOfferVideo.scss';
import useStores from '../../../hooks/useStores';
import liikumiseksloodud from '../../../images/liikumiseksloodud.svg';
import liikumiseksloodudBlack from '../../../images/liikumiseksloodud-black.svg';

const JobOfferVideo = () => {
  const { jobOffers } = useStores();

  const scrollToRef = () => {
    if (jobOffers.ref.current) {
      jobOffers.ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };
  return (
    <section className="component-jobOfferVideo">
      <div className="component-jobOfferVideo-container">
        <video
          className="component-jobOfferVideo-desktop"
          width="100%"
          autoPlay
          muted
          loop
          playsInline
        >
          <source
            src="https://rademar-media.fra1.cdn.digitaloceanspaces.com/video/tuletoole.mp4"
            type="video/mp4"
          />
        </video>
        <div className="component-jobOfferVideo-mobile">
          <video
            className="component-jobOfferVideo-mobile__video"
            width="100%"
            autoPlay
            muted
            loop
            playsInline
          >
            <source
              src="https://rademar-media.fra1.cdn.digitaloceanspaces.com/video/tuletoole-square.mp4"
              type="video/mp4"
            />
          </video>
        </div>

        <div className="component-jobOfferVideo-content">
          <div className="component-jobOfferVideo-svgWhite">
            <img src={liikumiseksloodud} alt="" />
          </div>
          <div className="component-jobOfferVideo-svgBlack">
            <img src={liikumiseksloodudBlack} alt="" />
          </div>
          <div onClick={scrollToRef} className="component-jobOfferVideo-button">
            <p>Liitu meie inspireeriva ja ühtehoidva tiimiga!</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default JobOfferVideo;
